import { z } from 'zod'

export const numberString = z
  .string()
  .refine(
    (s) => Number(s).toString() === s,
    (v) => ({ message: `should be a number, but got ${v}` })
  )
  .transform((s) => Number(s))

export const SlippageString = z
  .string()
  .refine(
    (s) => Number(s).toString() === s && Number(s) >= 0 && Number(s) <= 100,
    (_) => ({ message: `Invalid slippage` })
  )
  .transform((s) => Number(Number(s).toFixed(2)))

export const BuyPresetSolAmountString = z
  .string()
  .refine(
    (s) => Number(s).toString() === s && Number(s) >= 0.00001 && Number(s) <= 100,
    (_) => ({ message: `Invalid buyPresetSolAmount` })
  )
  .transform((s) => Number(Number(s).toFixed(5)))

export const SellPresetPercentageBpsString = z
  .string()
  .refine(
    (s) => Number(s).toString() === s && Number(s) >= 0 && Number(s) <= 100,
    (_) => ({ message: `Invalid sellPresetPercentageBps` })
  )
  .transform((s) => Number(Number(s).toFixed(2)))
